import React from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Functional component or custom hook to access route parameters
const ArticleSite = () => {
  const { slug } = useParams();
  return <_ArticleSite slug={slug} />;
};

class _ArticleSite extends React.Component {

  constructor(props) {
    super(props);
    this.slug = this.props.slug
    this.state = {
      title: 'Loading..',
      content: 'Loading..',
      date: 'Loading..',
      category: 'Loading..',
      anchors: [],
      attachment_text: 'External Resources',
      articleLoaded: false
    }
  }

  componentDidMount() {
    if (this.state.articleLoaded === false) {
      axios.get(process.env.REACT_APP_API_ENDPOINT + '/v1/items/' + this.slug)
        .then(res => {
          const regex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/g;

          // Extract URL-text pairs using the regular expression
          let matches = {};
          let match;
          while ((match = regex.exec(res.data[0].a)) !== null) {
            let url = match[1];
            let text = match[2];
            matches[url] = text;
          }
          console.log(matches)
          this.setState((state) => {
            return {
              title: res.data[0].t,
              content: res.data[0].b,
              date: res.data[0].pd,
              category: res.data[0].c,
              anchors: matches,
              attachment_text: res.data[0].c == 'Swiss National Bank' ? 'Continue reading' : state.attachment_text,
              articleLoaded: true
            }
          })
        })
    }
  }

  render() {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(this.state.content, 'text/html');
    const paragraphs = Array.from(parsedHTML.querySelectorAll('p'));
    const listItems = Array.from(parsedHTML.querySelectorAll('ul li, ol li'));
    return (
      <HelmetProvider>
        <div className="">

          <Helmet>
            <title>{this.state.title}</title>
            <meta name="description" content={this.state.content.slice(0, 200)} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={this.state.title + " | Financial Coffee"} />
            <meta property="og:description" content={this.state.content.slice(0, 200)} />
          </Helmet>

          <div className='center-readable c-read-h-margins'>
            <h1>{this.state.title}</h1>
            <div><p>{this.state.category}, {new Date(this.state.date).toLocaleString()}</p></div><br></br>
            {paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph.textContent}</p>
            ))}
            <ul>
              {listItems.map((item, index) => (
                <li key={index}>{item.textContent}</li>
              ))}
            </ul>
            {Object.entries(this.state.anchors).length > 0 && (
              <h2><br></br>{this.state.attachment_text}</h2>
            )}
            {Object.entries(this.state.anchors).map(([url, text], index) => (
              <div key={index}>
                <a className="minimal-anchor" href={url} target="_blank">{text} <img class='minimal-anchor-icon' src="external-link.svg" alt="external link icon" /></a>
              </div>
            ))}
          </div>

        </div>
      </HelmetProvider>
    );
  }
}

// Export the wrapper component instead of the class component
export default ArticleSite;