import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import InsiderTransactionsTable from './InsiderTransactionsTable ';
import Selector from './Selector';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { formatNumberToKString, parseFloatToUSString } from '../utils';
import TransactionTrendsGraph from './TransactionTrendsGraph';

const InspectCompany = () => {
    const { company } = useParams();
    return <_InspectCompany company={company} />;
};

class _InspectCompany extends Component {

    constructor(props) {
        super(props);
        this.company = this.props.company;
        this.endpoint = `${process.env.REACT_APP_API_ENDPOINT}/v1/items/insider/company/${this.company}`;
        this.filters = { 'Last 3 Months': 'currq', 'Current Month': 'currm', 'Last 12 Months': 't12m' };
        this.state = {
            transactions: [],
            companyName: this.props.company,
            allTransactions: [],
            insiderLoaded: false,
            activeFilter: this.filters['Last 3 Months'],
            error: null,
        };
        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        if (!this.state.insiderLoaded) {
            axios.get(this.endpoint)
                .then(res => {
                    const sortedTransactions = res.data.sort((a, b) => new Date(b.td) - new Date(a.td));
                    if (sortedTransactions.length === 0) {
                        this.setState({
                            error: 'Sorry, there is currently no data available for your request.',
                            insiderLoaded: true,
                        });
                    } else {
                        this.setState({
                            allTransactions: sortedTransactions,
                            transactions: sortedTransactions,
                            insiderLoaded: true,
                            companyName: sortedTransactions[0].com,
                        });
                    }
                })
                .catch(err => this.setState({ error: 'Error loading data' }));
        }
    }

    filter(event, key) {
        let updatedTransactions = this.state.allTransactions;
        const currentDate = new Date();

        if (key === this.filters['Current Month']) {
            updatedTransactions = this.state.allTransactions.filter(transaction => {
                const transactionMonth = new Date(transaction.td).getMonth();
                return transactionMonth === currentDate.getMonth();
            });
        } else if (key === this.filters['Last 3 Months']) {
            updatedTransactions = this.state.allTransactions.filter(transaction => {
                const transactionDate = new Date(transaction.td);
                return transactionDate >= new Date(currentDate.setMonth(currentDate.getMonth() - 2));
            });
        } else if (key === this.filters['Last 12 Months']) {
            updatedTransactions = this.state.allTransactions.filter(transaction => {
                const transactionDate = new Date(transaction.td);
                return transactionDate >= new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
            });
        }

        this.setState({
            transactions: updatedTransactions,
            activeFilter: key,
        });
    }

    render() {
        const { transactions, companyName, insiderLoaded, error } = this.state;
        const hasTransactions = transactions.length > 0;

        let buyCount = 0;
        let sellCount = 0;
        let maxValue = Number.NEGATIVE_INFINITY;
        let minValue = Number.POSITIVE_INFINITY;
        let buyCountCurrentMonth = 0;
        let sellCountCurrentMonth = 0;
        let otherCountCurrentMonth = 0;
        let uniqueStrings = {};
        let totalTransactionsValue = 0;
        let buyPercentage = 0;
        let sellPercentage = 0;
        let otherPercentage = 0;
        let roleCounts = {};

        if (hasTransactions) {
            transactions.forEach(transaction => {
                const { tt, a, p, o, td } = transaction;
                const amount = parseFloat(a);
                const price = parseFloat(p);
                const value = price * amount;
                const transactionDate = new Date(td);

                if (value > maxValue) {
                    maxValue = value;
                }
                if (value < minValue) {
                    minValue = value;
                }

                if (tt === 'P' || tt === 'M') {
                    buyCount += amount;
                    if (transactionDate.getMonth() === new Date().getMonth()) {
                        buyCountCurrentMonth += amount;
                    }
                } else if (tt === 'S') {
                    sellCount += amount;
                    if (transactionDate.getMonth() === new Date().getMonth()) {
                        sellCountCurrentMonth += amount;
                    }
                } else {
                    if (transactionDate.getMonth() === new Date().getMonth()) {
                        otherCountCurrentMonth += amount;
                    }
                }

                uniqueStrings[o] = true;
                roleCounts[o] = (roleCounts[o] || 0) + amount;
            });

            totalTransactionsValue = buyCountCurrentMonth + sellCountCurrentMonth + otherCountCurrentMonth;

            if (totalTransactionsValue > 0) {
                buyPercentage = (buyCountCurrentMonth / totalTransactionsValue) * 100;
                sellPercentage = (sellCountCurrentMonth / totalTransactionsValue) * 100;
                otherPercentage = (otherCountCurrentMonth / totalTransactionsValue) * 100;
            }
        }

        const uniqueCount = Object.keys(uniqueStrings).length;
        const sortedRoleCounts = Object.entries(roleCounts).sort((a, b) => b[1] - a[1]).slice(0, 3);
        

        if (error) {
            return <div className="center-readable c-read-h-margins"><h1>{this.company}</h1><p>{error}</p></div>;
        }
        if (!insiderLoaded) {
            return <div className="center-readable c-read-h-margins"><h1>Loading...</h1></div>;
        }

        const summaryString = `Discover ${companyName}'s latest insider activity. ${uniqueCount} insiders have been trading the ticker ${this.company} with a predominance of ${buyCount > sellCount ? 'Buy' : 'Sell'} transactions. Discover more insights on Financial Coffee..`;

        return (
            <HelmetProvider>
                <Helmet>
                    <title>{companyName} Insider Transactions | {this.company} Insider Activity - Financial Coffee</title>
                    <meta
                        name="description"
                        content={summaryString}
                    />
                    <meta name="robots" content="index, follow" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="keywords" content="insider transactions, insider trading, insider screener, management transactions, stock market" />
                    <link rel="canonical" href={"https://www.financialcoffee.com/inspect/" + this.company} />

                </Helmet>
                <div className="center-readable c-read-h-margins">
                    <h1>{companyName}</h1>
                    <Selector filters={this.filters} filterFunc={this.filter} />
                    <h3>Activity</h3>
                    <TransactionTrendsGraph transactions={transactions} />
                    <h3>Summary</h3>
                    <div className="card-grid">
                        <div className="card">
                            <p style={{ fontSize: 'small' }}>Trades</p>
                            <p>{transactions.length}</p>
                        </div>
                        <div className="card">
                            <p style={{ fontSize: 'small' }}>Buy vs. Sell</p>
                            <p>{buyCount > sellCount ? 'Buy' : 'Sell'}</p>
                        </div>
                        <div className="card">
                            <p style={{ fontSize: 'small' }}>Insiders</p>
                            <p>{uniqueCount}</p>
                        </div>
                        <div className="card">
                            <p style={{ fontSize: 'small' }}>Top Insider by Volume</p>
                            <p>{sortedRoleCounts[0][0]}</p>
                        </div>
                        <div className="card">
                            <p style={{ fontSize: 'small' }}>Shares Bought</p>
                            <p>{parseFloatToUSString(buyCount, 0)}</p>
                        </div>
                        <div className="card">
                            <p style={{ fontSize: 'small' }}>Shares Sold</p>
                            <p>{parseFloatToUSString(sellCount, 0)}</p>
                        </div>

                        <div className="card">
                            <p style={{ fontSize: 'small' }}>Value Range</p>
                            <p>{formatNumberToKString(minValue)}-{formatNumberToKString(maxValue)}</p>
                        </div>

                    </div>

                    <h3>Insider Transactions</h3>
                    <InsiderTransactionsTable transactions={transactions} hasTransactions={hasTransactions} />
                    <p>
                        <a className='minimal-anchor' href='/'>home</a> / <a className='minimal-anchor' href='/insider'>insider filings</a> / {this.company.toLowerCase()}
                    </p>
                </div>
            </HelmetProvider>)
    }
}

export default InspectCompany;