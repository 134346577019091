import React from "react";
import '../styles/BottomFooter.css';

class BottomFooter extends React.Component {
  render() {
    return (
      <footer>
        <div className="center-readable c-read-h-margins">
          <div className="foot-clm-ctn">
            
            <div className="foot-clm">
            <p className="clm-title">Explore</p>
            <p><a className="minimal-anchor" href="/">Financial News</a></p>
              <p><a className="minimal-anchor" href="/banks">Central Banks Announcements</a></p>
              <p><a className="minimal-anchor" href="/regulatory">Regulatory Supervisory Authorities</a><div className="label-base-small">new</div></p>
              <p className="clm-title">Screeners</p>
              <p><a className="minimal-anchor" href="/insider">Insider Transactions</a><div className="label-base-small">new</div></p>
            </div>
            <div className="foot-clm">
              <p className="clm-title">About</p>              
              <p><a className="minimal-anchor" href="https://www.linkedin.com/company/financialcoffee/">Financial Coffee on LinkedIn</a></p>
              <p><a className="minimal-anchor" href="/disclaimer">Disclaimer</a></p>
            </div>
            {/* <div className="foot-clm">
              <p className="clm-title">Other</p>
              <p><a className="minimal-anchor">API</a></p>
              <p><a className="minimal-anchor">Insider Transactions</a></p>
            </div> */}
          </div>
          <div>&copy; 2024 financial coffee, all rights reserved.</div>
        </div>
      </footer>
    );
  }
}

export default BottomFooter;
