function getDateDayDifferenceString(date) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    let parsedDate = Date.parse(date)
    if (isNaN(parsedDate)) {
        parsedDate = today
    }
    date = new Date(parsedDate)

    const diffInDays = Math.floor((today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

    if (diffInDays === 0) {
        return 'Today';
    } else if (diffInDays === 1) {
        return 'Yesterday';
    } else {
        return `${diffInDays} days ago`;
    }
}

function parseFloatToUSString(number, fractionDigits = 2, isInteger = false) {
    let parsedFloat = parseFloat(number);
    if (isNaN(parsedFloat) || !isFinite(parsedFloat)) {
        return '0';
    }

    if (parsedFloat > 0 && parsedFloat <= 0.01) {
        return '< 0.01';
    }

    return parsedFloat.toFixed(fractionDigits).toLocaleString('en-US');
}

function formatNumberToKString(number) {
    let parsedFloat = parseFloat(number);
    if (isNaN(parsedFloat) || !isFinite(parsedFloat)) {
        return '0';
    }

    if (parsedFloat < 1000) {
        return parsedFloat.toLocaleString('en-US');
    } else if (parsedFloat < 1000000) {
        return (parsedFloat / 1000).toFixed(1) + 'K';
    } else {
        return (parsedFloat / 1000000).toFixed(1) + 'M';
    }
}

function calculateOwnershipDelta(postTradeShares, tradeShares, tradeCode = 'P') {
    let deltaPercentage = (parseFloat(tradeShares) / parseFloat(postTradeShares)) * 100;
    let sign = '+';
    
    // Determine sign based on trade code
    if (tradeCode === 'P' || tradeCode === 'I' || tradeCode === 'M' || tradeCode === 'G') {
        sign = '+';
    } else {
        sign = '-';
    }

    return sign + parseFloatToUSString(Math.abs(deltaPercentage), 1) + '%';
}

module.exports = {
    getDateDayDifferenceString,
    parseFloatToUSString,
    calculateOwnershipDelta,
    formatNumberToKString
};