import React from 'react'
import LEDCarousel from './LEDCarousel';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import BottomFooter from './BottomFooter';

class BaseLayout extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      searchQuery: null,
      carouselTickers: []
    }
  }


  componentDidMount() {
    let carouselTickers = fetch(process.env.REACT_APP_API_ENDPOINT + '/v1/quotes/major').then((res) => res.json());
    carouselTickers.then((json) => {
      this.setState({
        carouselTickers: json,
      })
    })
  }


  render() {
    return (
      <div>
        <LEDCarousel tickers={this.state.carouselTickers}></LEDCarousel>
        <Header
          onSearch={this.props.onSearch}
          onSuggestionClick={this.props.onSuggestionClick}
          onClearSuggestions={this.props.onClearSuggestions}
          searchSuggestions={this.props.searchSuggestions}
          updateSuggestions={this.props.updateSuggestions}
        />
        {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
        <Outlet />
        <BottomFooter />
      </div>
    );
  }
}

export default BaseLayout;