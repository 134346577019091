import React, { Component } from "react";
import axios from 'axios';
import { Helmet, HelmetProvider } from "react-helmet-async";
import ArticleSnippet from "./ArticleSnippet";
import Selector from "./Selector";

class Banks extends Component {
    constructor(props) {
        super(props);
        let tmpReleases = []
        this.maxHeadLines = 30
        this.bankIdMap = {
            'Federal Reserve': 'fed',
            'European Central Bank': 'ecb',
            'Swiss National Bank': 'snb',
            'Bank Of Japan': 'boj',
            'Bank Of England': 'boe',
            'Bank Of Canada': 'boc',
            'Reserve Bank of Australia': 'rba',
            'Reserve Bank of India': 'rbi',
            'Bank of Italy': 'boi',
            'Deutsche Bundesbank': 'dbb',
        }
        for (var i = 0; i < this.maxHeadLines; i++) {
            tmpReleases.push({ id: i, t: 'Fetching...', l: undefined, c: 'Fetching', pd: new Date() })
        }
        let bankReleases = {}
        for (const [longKey, key] of Object.entries(this.bankIdMap)) {
            bankReleases[key] = tmpReleases
        }

        this.state = {
            bankReleases: bankReleases,
            bankReleasesActiveKey: 'fed',
            releasesLoaded: false
        }

        this.filterPressReleases = this.filterPressReleases.bind(this);

    }

    componentDidMount() {
        /* BANK RELEASES */
        if (this.state.releasesLoaded === false) {
            axios.get(process.env.REACT_APP_API_ENDPOINT + '/v1/items/releases/banks')
                .then(res => {
                    let newBankReleases = {};

                    for (const [longKey, key] of Object.entries(this.bankIdMap)) {
                        newBankReleases[key] = [];
                    }

                    for (var i = 0; i < res.data.length; i++) {
                        const bankKey = this.bankIdMap[res.data[i].c];
                        if (newBankReleases[bankKey] === undefined) {
                            continue; // Skip this iteration
                        }
                        newBankReleases[bankKey].push(res.data[i]);
                    }

                    let aggregatedArticles = newBankReleases['snb'].flat(1);
                    newBankReleases['snb'] = aggregatedArticles.sort((a, b) => new Date(b.pd) - new Date(a.pd));

                    this.setState({
                        bankReleases: newBankReleases,
                        releasesLoaded: true
                    });
                })
                .catch(err => console.log(err));
        }


    }

    filterPressReleases(event, key) {
        this.setState({ bankReleasesActiveKey: key })
    }

    render() {
        return (
            <HelmetProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Financial Coffee | Monetary Economics | Central Banks</title>
                        <meta
                            name="description"
                            content="Explore the economic chessboard with our page dedicated to central bank statements and updates. Unpack the nuances of monetary decisions, strategic shifts, and policy updates straight from the source. Whether you're a finance connoisseur or new to economic landscapes, our concise insights offer a front-row seat to the dynamic world of central banking. Stay economically tuned in with real-time updates from central banks around the globe."
                        />
                        <link rel="canonical" href="%PUBLIC_URL%/banks" />
                    </Helmet>
                    <div className='center-readable c-read-h-margins'>
                        <h1>Updates from Central Bank Authorities</h1>
                        <Selector filters={this.bankIdMap} filterFunc={this.filterPressReleases} />
                        <div className='news-container'>
                            {this.state.bankReleases[this.state.bankReleasesActiveKey].slice(0, this.maxHeadLines - 1).map((release, i) =>
                                <ArticleSnippet
                                    key={i}
                                    id={release.id}
                                    title={release.t}
                                    link={release.l}
                                    category={release.c}
                                    date={release.pd}
                                    thumbnail={release.i}
                                    content={release.b}
                                />)}
                        </div>


                    </div>
                </div>
            </HelmetProvider>
        );
    }
}

export default Banks;
