class _BaseSuggestionItem {
    constructor(url, title) {
        this.url = url;
        this.title = title;
    }
}


class NewsSuggestion extends _BaseSuggestionItem {

    constructor(url, title, source) {
        super(url, title);
        this.source = source;
    }

    handleSuggestionClick() {
        window.location.href = this.url;
    }

    div(index) {
        return (
            <div
                key={index}
                className="suggestion-item"
                onClick={() => this.handleSuggestionClick()}
            >
                <div className="search-item-type">News</div>
                <div className="search-item-title">{this.title}</div>
            </div>
        )
    }
}

class CompanySuggestion extends _BaseSuggestionItem {

    constructor(url, company, ticker) {
        super(url, company);
        this.ticker = ticker;
    }

    handleSuggestionClick() {
        window.location.href = this.url;
    }

    div(index) {
        return (
            <div
                key={index}
                className="suggestion-item"
                onClick={() => this.handleSuggestionClick()}
            >
                <div className="search-item-type">Company Profile</div>
                <div className="search-item-title">{this.title}</div>
            </div>
        )
    }
}
export { NewsSuggestion, CompanySuggestion };