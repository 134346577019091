import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="recharts-tooltip-wrapper">
                <div className="tooltip-container">
                    <div className="mt-2">
                        <div>
                            <span className="tooltip-span" style={{ color: 'rgb(19, 115, 51)' }}>Shares purchased: </span>
                            <span className="tooltip-span">{payload[0]?.value || '$0'}</span>
                        </div>
                        <div>
                            <span className="tooltip-span" style={{ color: 'rgb(165,14,14)' }}>Shares sold: </span>
                            <span className="tooltip-span">{payload[1]?.value || '$0'}</span>
                        </div>

                        <div>
                            <span className="tooltip-span" style={{ color: 'rgb(25, 103, 210)' }}>Shares granted: </span>
                            <span className="tooltip-span">{payload[2]?.value || '$0'}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

const TransactionTrendsGraph = ({ transactions }) => {
    // Transform the transactions data into a format suitable for the chart
    const data = transactions.reduce((acc, transaction) => {
        const date = new Date(transaction.td);
        const yearMonthDay = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        const existingEntry = acc.find(entry => entry.date === yearMonthDay);

        if (existingEntry) {
            if (transaction.tt === 'P' || transaction.tt === 'M') {
                existingEntry.purchase += parseFloat(transaction.a);
            } else if (transaction.tt === 'S') {
                existingEntry.sell += parseFloat(transaction.a) * -1; // Making sell transactions negative
            } else if (transaction.tt === 'G' || transaction.tt == 'A') {
                existingEntry.grants += parseFloat(transaction.a);
            }
        } else {
            acc.push({
                date: yearMonthDay,
                purchase: (transaction.tt === 'P' || transaction.tt === 'M') ? parseFloat(transaction.a) : 0,
                sell: transaction.tt === 'S' ? parseFloat(transaction.a) * -1 : 0, // Making sell transactions negative
                grants: (transaction.tt === 'G' || transaction.tt == 'A') ? parseFloat(transaction.a) : 0
            });
        }

        return acc;
    }, []).sort((a, b) => new Date(a.date) - new Date(b.date));

    // Function to format Y axis values to show in 'K'
    const formatYAxis = (tickItem) => {
        return `${tickItem / 1000}K`;
    };

    return (
        <ResponsiveContainer width="90%" height={200}>
            <BarChart
                data={data}
                margin={{
                    top: 10, right: 0, left: 0, bottom: 30,
                }}
                barCategoryGap="50%"
                barGap={0}
            >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="date" angle={-45} textAnchor="end" interval={0} tick={{ fontSize: 10 }} />
                <YAxis tick={{ fontSize: 12 }} tickFormatter={formatYAxis} />
                {/* <Tooltip
                    contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', border: '1px solid #ccc' }}
                    cursor={{ fill: 'rgba(230, 230, 230, 0.5)' }}
                /> */}
                <Tooltip
                    content={<CustomTooltip />} // Use the custom tooltip component
                    cursor={{ fill: 'rgba(230, 230, 230, 0.5)' }}
                />
                <Bar dataKey="purchase" fill="rgb(230, 244, 234)" stroke="rgb(19, 115, 51)" barSize={5.5} />
                <Bar dataKey="sell" fill="rgb(252, 232, 230)" stroke="rgb(165,14,14)" barSize={5.5} />
                <Bar dataKey="grants" fill="rgb(232, 240, 254)" stroke="rgb(25, 103, 210)" barSize={5.5} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default TransactionTrendsGraph;
