import React, { Component } from 'react';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Selector from './Selector';
import InsiderTransactionsTable from './InsiderTransactionsTable ';

class InsiderTransactions extends Component {
  constructor(props) {
    super(props);
    this.endpoint = `${process.env.REACT_APP_API_ENDPOINT}/v1/items/insider?start=0&end=500`;

    this.filters = {
      Latest: 'latest',
      'Purchases': 'buys',
      'Sells': 'sells',
      'USA': 'us-only',
      'Switzerland': 'swiss-only',
      'Biggest': 'big',
      'Penny Stocks': 'penny',
    };
    this.state = {
      title: 'Insider Transactions',
      transactions: [],
      allTransactions: [],
      insiderLoaded: false,
      activeFilter: this.filters.Latest,
    };
    this.filter = this.filter.bind(this);
  }

  filter(event, key) {
    let updatedTransactions = this.state.allTransactions;

    if (key === this.filters['Purchases']) {
      updatedTransactions = this.state.allTransactions.filter(transaction => transaction.tt === 'P' || transaction.tt === 'M');
    } else if (key === this.filters['Sells']) {
      updatedTransactions = this.state.allTransactions.filter(transaction => transaction.tt === 'S');
    } else if (key === this.filters['Biggest']) {
      updatedTransactions = this.calculateTopTransactions(this.state.allTransactions);
    } else if (key === this.filters['Penny Stocks']) {
      updatedTransactions = this.filterPennyStocks(this.state.allTransactions);
    } else if (key === this.filters['USA']) {
      updatedTransactions = this.filterUSTransactions(this.state.allTransactions);
    } else if (key === this.filters['Switzerland']) {
      updatedTransactions = this.filterSwissTransactions(this.state.allTransactions);
    }

    this.setState(prevState => ({
      transactions: updatedTransactions,
      activeFilter: key,
    }));
  }

  componentDidMount() {
    if (!this.state.insiderLoaded) {
      axios.get(this.endpoint)
        .then(res => {
          const sortedTransactions = res.data.sort((a, b) => new Date(b.td) - new Date(a.td));
          this.setState({
            allTransactions: sortedTransactions,
            transactions: sortedTransactions,
            insiderLoaded: true,
          });
        })
        .catch(err => console.error(err));
    }
  }

  calculateTopTransactions(transactions) {
    return transactions
      .map(transaction => ({
        ...transaction,
        tradeValue: parseFloat(transaction.p) * parseFloat(transaction.a) / 1000
      }))
      .sort((a, b) => b.tradeValue - a.tradeValue)
      .slice(0, 10);
  }

  filterPennyStocks(transactions) {
    return transactions
      .filter(transaction => parseFloat(transaction.p) > 0 && parseFloat(transaction.p) < 5)
      .sort((a, b) => parseFloat(a.p) - parseFloat(b.p));
  }

  filterUSTransactions(transactions) {
    return transactions.filter(transaction => transaction.c === '$');
  }


  filterSwissTransactions(transactions) {
    return transactions.filter(transaction => transaction.c === 'CHF');
  }



  render() {
    const { transactions } = this.state;
    const hasTransactions = transactions.length > 0;


    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <title>Recently Filed Insider Transactions | Insider Transactions Screener - Financial Coffee</title>
            <meta
              name="description"
              content="Track the latest insider transactions in the US and Swiss markets with Financial Coffee's insider screener.
             Stay updated with insider trading activities, analyze insider buying and selling patterns to gain a competitive edge in the stock market."
            />
            <meta name="robots" content="index, follow" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="keywords" content="insider transactions, insider trading, insider screener, management transactions, stock market" />
            <link rel="canonical" href="https://www.financialcoffee.com/insider" />

          </Helmet>
          <div className="center-readable c-read-h-margins">
            <h1>Recently filed insider transactions</h1>
            <Selector filters={this.filters} filterFunc={this.filter} />
            <InsiderTransactionsTable
              transactions={transactions}
              hasTransactions={hasTransactions}
            />
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default InsiderTransactions;
