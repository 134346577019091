import React from 'react'
import '../styles/Header.css';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';

class Header extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <header>
                <div className='nav-container c-read-h-margins'>
                    <Link className='link minimal-anchor' to='/'>home</Link>
                    <Link className='link minimal-anchor' to='/banks'>banks</Link>
                    <Link className='link minimal-anchor' to='/regulatory'>regulatory</Link>
                    <Link className='link minimal-anchor' to='/insider'>insider</Link>
                </div>
                <div className='nav-container c-read-h-margins'>
                    <SearchBar
                        onSearch={this.props.onSearch}
                        onSuggestionClick={this.props.handleSuggestionClick}
                        onClearSuggestions={this.props.onClearSuggestions}
                        searchSuggestions={this.props.searchSuggestions} 
                        updateSuggestions={this.props.updateSuggestions}
                    />
                </div>
            </header>
        );
    }
}

export default Header;