import React, { Component } from "react";
import axios from 'axios';
import { Helmet, HelmetProvider } from "react-helmet-async";
import ArticleSnippet from "./ArticleSnippet";
import ShowMore from "./ShowMore";

class Regulatory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            newsLoaded: false,
            initialLoading: true,
            start: 0,
            end: 10,
            hasMore: true,
            loadingNews: false, // Separate loading state for news
        }

        this.showMoreItems = this.showMoreItems.bind(this);

    }

    componentDidMount() {
        this.fetch(this.state.start, this.state.end);
    }

    fetch(start, end) {
        this.setState({ loadingNews: true }); // Set loadingNews to true
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/items/news/institutions?start=${start}&end=${end}`)
            .then(res => {
                const newNews = res.data;
                this.setState(prevState => ({
                    news: [...prevState.news, ...newNews],
                    newsLoaded: true,
                    hasMore: newNews.length === (end - start),
                    loadingNews: false, // Set loadingNews to false on successful fetch
                    initialLoading: false
                }));
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingNews: false }); // Set loadingNews to false on error
            });
    }

    showMoreItems() {
        const { end } = this.state;
        const newStart = end;
        const newEnd = end + 20;
        this.setState({ start: newStart, end: newEnd }, () => {
            this.fetch(this.state.start, this.state.end);
        });
    }

    render() {
        return (
            <HelmetProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Financial Coffee | Regulatory News and Updates from Global Supervisory Authorities</title>
                        <meta name="description" content="Stay informed with streamlined access to the latest news and regulatory announcements from global supervisory authorities in finance." />
                        <link rel="canonical" href="%PUBLIC_URL%/regulatory" />
                    </Helmet>
                    <div className='center-readable c-read-h-margins'>
                        <h1>Regulatory Roundup</h1>
                        {/* <Selector filters={this.bankIdMap} filterFunc={this.filterPressReleases} /> */}
                        <div className='news-container'>
                            {this.state.initialLoading && this.state.loadingNews && [...Array(10)].map((_, i) => (
                                <ArticleSnippet
                                    key={i}
                                    id={i}
                                    title="Fetching..."
                                    link="#"
                                    category="Fetching"
                                    content="Fetching..."
                                    date={new Date()}
                                />
                            ))}
                            {this.state.news.slice(0, this.maxHeadLines).map(release => (
                                <ArticleSnippet
                                    key={release.id}
                                    id={release.id}
                                    title={release.t}
                                    link={release.l}
                                    category={release.c}
                                    content={release.b}
                                    date={release.pd}
                                    thumbnail={release.i}
                                    tickers={release.mt}
                                />
                            ))}
                            {/* ShowMore for news */}
                            <ShowMore onClick={this.showMoreItems} hasMore={this.state.hasMore} loading={this.state.loadingNews} />
                        </div>


                    </div>
                </div>
            </HelmetProvider>
        );
    }
}

export default Regulatory;
